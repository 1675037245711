import "./App.css";
// NOTE: data is assumed to be locked to 4 columns
import data from "./data/data.json";
import Cell from "./components/Cell";

console.log(data);

function App() {
  // Loop over each row
  const cells = data.map((row, rowIndex) => {
    // Loop over each column
    return row.map((col, colIndex) => {
      return (
        <Cell
          key={rowIndex+''+colIndex}
          text={col.text}
          align={col.align}
          valign={col.valign}
          colspan={col.colspan}
          textcolor={col.textcolor}
        ></Cell>
      );
    });
  });

  return <div className="grid-container">{cells}</div>;
}

export default App;
