const Cell = ({ text, align, valign, colspan, textcolor }) => {
  const classNames = [
    "grid-cell",
    "span-" + colspan,
    "align-" + align,
    "valign-" + valign,
  ];

  return (
    <div className={classNames.join(" ")} style={{ color: textcolor }}>
      {text}
    </div>
  );
};

export default Cell;
